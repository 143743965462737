const hamburger = document.querySelector('.hamburger--js');
const navigation = document.querySelector('.navigation--js');
const navigationLinks = document.querySelectorAll('.navigation__link--js');

hamburger.addEventListener('click', () => {
    navigation.classList.toggle('navigation--open');
});

navigationLinks.forEach(link => {
    link.addEventListener('click', () => {
        navigation.classList.remove('navigation--open');
    });
});